<template>
  <div>
    <div class="card-footer py-4 d-flex justify-content-between align-items-center">
      <el-pagination
        background
        :current-page="currentPage"
        @current-change="onCurrentChange"
        :total="items.length"
        :page-count="pageCount"
        :page-size="pageSize"
        layout="prev, pager, next">
      </el-pagination>
      <el-select
        class="select-primary mr-2"
        v-model="pageSize" filterable>
          <el-option class="select-primary ml-1"
            value="10"
            label="10"
            key="10"></el-option>
          <el-option class="select-primary ml-1"
            value="50"
            label="50"
            key="50"></el-option>
          <el-option class="select-primary ml-1"
            :value="listLength"
            :label="$t('message.page_size_all', { allCount: listLength })"
            key="100"></el-option>
        </el-select>
    </div>
    
    <el-table
      ref="imTable"
      :data="paginatedItems"
      class="table-responsive table-flush"
      header-row-class-name="thead-light"
      cell-class-name="clickable"
      :row-class-name="tableRowClassName"
      @row-click="onClick"
      :default-sort="{prop: 'tempsort', order: 'ascending'}"
      style="width: 100%"
      stripe
      @selection-change="handleSelectionChange">
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column
        type="index">
      </el-table-column>
        <el-table-column
          :label="$t('message.motive')"
          prop="tempsort"
          width="200">
          <template v-slot="{row}">
            <el-tooltip placement="right">
                <div slot="content">
                  <img v-if="hasImageUrl(row, 'designs')" :src="imageUrl(row, 'designs')" style="width: 400px;"/>
                </div>
                <img v-if="hasImageUrl(row, 'annotation')" :src="imageUrl(row, 'annotation')" style="width: 100%"/>
              </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="key"
          :label="$t('message.id')"
          width="120">
        </el-table-column>
        <el-table-column
          prop="address.address.name"
          :label="$t('message.einsatzort')" width="180"
          >
        </el-table-column>
        <el-table-column
          prop="area.name"
          :label="$t('message.area')" width="180">
        </el-table-column>
        <el-table-column
        prop="bhb_position.name"
        :label="$t('message.position')" width="220">
      </el-table-column>
  
      <el-table-column :label="$t('message.width')" width="120">
        <template v-slot="{row}">
          <template v-if="!!row.materials">
            <div v-for="mat in row.materials.filter(m => m.type === 'MAT')">{{mat.width | number}}</div>
          </template>
        </template>
      </el-table-column>
      <el-table-column :label="$t('message.height')" width="120">
        <template v-slot="{row}">
          <template v-if="!!row.materials">
            <div v-for="mat in row.materials.filter(m => m.type === 'MAT')" v-if="!!row.materials">{{mat.height | number}}</div>
          </template>
        </template>
      </el-table-column>
      <el-table-column :label="$t('message.amount')" width="120">
        <template v-slot="{row}">
          <div v-if="!!row.materials">{{row.materials[0].quantity}}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop=""
        :label="$t('message.price_total')"
        width="130"
        align="right"
        header-align="right"
        v-if="currentUserRights.includes('BHB - Vollbild_Ansicht mit Preis')">
        <template v-slot="{row}">
          <span v-if="isCustomerAdr(currentUser, project, row.address)">
            <span v-if="row.price_percent>0">{{ row.price_percent }} %</span>
            <span v-else>{{ row.price_total | currency }}</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Material" min-width="200">
        <template v-slot="{row}">
          {{ row | firstmaterial('MAT', currentLocale) }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        width="150">
        <template v-slot="{row}">
          <template v-if="(currentUserRights.includes('bhb.export.priority_visible') && row.priority_internal === 1 && row.priority)
                          || (row.priority_internal === 0 && row.priority)">
            <el-tooltip placement="right" v-if="row.priority">
              <div slot="content">
                {{ row.priority_reason }}
              </div>
              <i class="fas fa-bolt active-star mr-2" ></i>
            </el-tooltip>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        :label="$t('message.state')"
        align="center"
        header-align="center"
        width="150">
        <template v-slot="{row}">
          <bhb-status-badge :state="row.state_bhb"></bhb-status-badge>
        </template>
      </el-table-column>
      <el-table-column
        prop="release"
        sortable
        :label="$t('message.economic_release')"
        header-align="center"
        align="center"
        width="180">
        <template v-slot="{row}">
          <base-checkbox :checked="row.rel_business == 1" disabled>
          </base-checkbox>
        </template>
      </el-table-column>
      <el-table-column
        prop="release"
        sortable
        :label="$t('message.actionPL')"
        header-align="center"
        align="center"
        width="180"
        v-if="currentUserRights.includes('bhb.status.can-modify-handlung-pl')">
        <template v-slot="{row}">
          <base-checkbox :checked="row.handlung_pl === 1" disabled>
          </base-checkbox>
        </template>
      </el-table-column>
      <el-table-column
        prop="release"
        sortable
        :label="$t('message.show_dv_erstellt')"
        header-align="center"
        align="center"
        width="180"
        v-if="currentUserRights.includes('bhb.status.can-modify-dv-erstellt')">
        <template v-slot="{row}">
          <base-checkbox :checked="row.dv_erstellt == 1" disabled>
          </base-checkbox>
        </template>
      </el-table-column>
    </el-table>

    <div class="card-footer py-4 d-flex justify-content-between align-items-center">
      <el-pagination
        background
        :current-page="currentPage"
        @current-change="onCurrentChange"
        :total="items.length"
        :page-count="pageCount"
        :page-size="pageSize"
        layout="prev, pager, next">
      </el-pagination>
       <el-select
        class="select-primary mr-2"
        v-model="pageSize" filterable>
          <el-option class="select-primary ml-1"
            value="10"
            label="10"
            key="10"></el-option>
          <el-option class="select-primary ml-1"
            value="50"
            label="50"
            key="50"></el-option>
          <el-option class="select-primary ml-1"
            :value="listLength"
            :label="$t('message.page_size_all', { allCount: listLength })"
            key="100"></el-option>
        </el-select>
    </div>
  </div>

</template>
<script>
import { mapGetters, mapMutations } from "vuex";

// Charts
import BhbListItem from "./ListItem";
import BhbStatusBadge from "@/components/BHBStatusBadge";
import DocumentImage from "@/components/DocumentImage";
import isCustomerAdr from "@/util/customerAdr";
import { isImage } from "@/util/data";

export default {
  name: "BhbListView",
  components: {
    BhbListItem,
    BhbStatusBadge,
    DocumentImage,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    project: {
      type: Object,
    },
    columns: {
      type: Number,
      default: 2,
    },
    docs: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "project",
      "currentUserRights",
      "selectedIMPositions",
      "currentLocale",
    ]),
    listLength() {
      if (this.pageSize === this.items.length -1) {
        this.$store.commit("SET_IM_PAGE_SIZE", this.items.length);
      }
      return this.items.length;
    },
    colClass() {
      const width =
        this.items.length < this.columns
          ? Math.floor(12 / this.items.length)
          : 12 / this.columns;
      return `col-md-${width}`;
    },
    pageCount() {
      return Math.ceil(this.items.length / this.pageSize);
    },
    paginatedItems() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.items.slice(start, end);
    },
    currentPage: {
      get() {
        return this.$store.getters.IMCurrentPage;
      },
      set(val) {
        this.$store.commit("SET_IM_CURRENT_PAGE", val);
      },
    },
    pageSize: {
      get() {
        return this.$store.getters.IMPageSize;
      },
      set(val) {
        this.$store.commit("SET_IM_PAGE_SIZE", val);
      },
    },
  },
  methods: {
    ...mapMutations(["UPDATE_SELECTED_IM_POSITIONS"]),
    onCurrentChange(pageNumber) {
      this.currentPage = pageNumber;
    },
    onClick({ id }) {
      const url = this.$router.resolve({
        path: `/project/${this.$route.params.id}/bhb/${id}`,
      });
      window.open(url.href, "_blank");
    },
    imageUrl(item, path, index = 0) {
      if (item.documents) {
        const found = item.documents
          .filter((doc) => doc.path === path)
          .reverse();
        if (found.length > 0) {
          if (isImage(found[0].mimetype)) {
            return found[0].url;
          }
          return "/app/img/empty.png";
        }
      }
      return "/app/img/empty.png";
    },
    hasImageUrl(item, path, index = 0) {
      if (item.documents) {
        const found = item.documents
          .filter((doc) => doc.path === path)
          .reverse();
        if (found.length > 0) {
          if (isImage(found[0].mimetype)) {
            return true;
          }
          return false;
        }
      }
      return false;
    },
    tableRowClassName({ row }) {
      if (row.state_bhb === "ABGELEHNT") {
        return "disabled-row";
      }
      if ((row.priority === 1 && this.currentUserRights.includes('bhb.export.priority_visible'))
          || (row.priority === 1 && row.priority_internal === 0)) {
        return "priority-row";
      }
      return "";
    },
    handleSelectionChange(val) {
      const selectedIds = val.map((p) => p.id);
      this.UPDATE_SELECTED_IM_POSITIONS(selectedIds);
    },
    isCustomerAdr,
  },
  mounted() {
    this.pageSize = 50;
  }
};
</script>
<style>
.disabled-row {
  opacity: 0.3;
  filter: grayscale(100%);
}
.priority-row {
  background: #ffffbb !important;
}
.el-table__row--striped.priority-row td {
  background: #ffffbb !important;
}
</style>
